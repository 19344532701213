<template>
  <div class="adminmeet">
    <div class="adminmeet_main">
      <div class="adminmeet_title_block">
        <div class="adminmeet_title">
          管理员：<span>{{ getAdminPhone() }}</span>
        </div>
      </div>
      <div class="adminmeet_banner">
        <div class="adminmeet_banner_info">
          <div class="adminmeet_banner_block">
            <div class="adminmeet_banner_content">
              <span style="color: #008f3d" class="adminmeet_title_bold">{{
                data.can_create_meeting
              }}</span>
              <span>总会议</span>
            </div>
          </div>
          <!-- <div class='adminmeet_banner_line'><span></span></div> -->
          <div class="adminmeet_banner_block">
            <div class="adminmeet_banner_content">
              <span style="color: #008f3d" class="adminmeet_title_bold">{{
                data.already_assigned_meeting_count
              }}</span>
              <span>已分配会议</span>
            </div>
          </div>
          <!-- <div class='adminmeet_banner_line'><span></span></div> -->
          <div class="adminmeet_banner_block">
            <div class="adminmeet_banner_content">
              <span style="color: #008f3d" class="adminmeet_title_bold">{{
                data.remaining_meetings_count
              }}</span>
              <span>待分配会议</span>
            </div>
          </div>
        </div>
        <div class="freezed_meeting" @click="goFreezeMeeting">已冻结会议：{{ data.discard_meeting || 0 }}</div>
      </div>
      <!-- <div class="adminmeet_func_btn">
                <el-button  size='small'  @click.native='openBook'>管理员操作手册</el-button>
                <el-button type='primary' size='small' @click.native='openCoursewareDetail'>课件预览</el-button>
            </div> -->
      <div class="adminmeet_title_second global_primary_text_color">
        已匹配医生(<span style="color: #000">
          {{ data.assigned_doctor_count }}</span
        >)
      </div>
      <div class="adminmeet_list">
        <ListScroll :url="$urls.admin.list" ref="ListScroll">
          <template v-slot:listItem="{ citems }">
            <div class="adminmeet_block" @click="openDetail(citems)">
              <div class="adminmeet_block_content">
                <div class="adminmeet_room">
                  <div class="adminmeet_room_next">
                    <div class="adminmeet_room_key global_primary_text_color">
                      姓名:&nbsp;
                    </div>
                    <div class="adminmeet_room_value">{{ citems.name }}</div>
                  </div>
                  <div class="adminmeet_room_next">
                    <div class="adminmeet_room_key global_primary_text_color">
                      医院:&nbsp;
                    </div>
                    <div class="adminmeet_room_value">
                      {{ citems.hospital }}
                    </div>
                  </div>
                </div>
                <div class="adminmeet_room">
                  <div class="adminmeet_room_key global_primary_text_color">
                    已分配会议:&nbsp;
                  </div>
                  <div class="adminmeet_room_value">{{ citems.count }}</div>
                </div>
                <div>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </template>
        </ListScroll>
      </div>

      <div class="adminmeet_footer">
        <div class="adminmeet_allocation">
          <el-button
            v-if="data.remaining_meetings_count"
            class="adminmeet_allocation_btn"
            size="small"
            @click.native="doAllocationMeet"
            >开始分配会议</el-button
          >
        </div>
        <el-button type="primary" size="small" @click.native="doLoginOut" round
          >退出登录</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ListScroll from "@/components/unit/ListScroll";
import store from "@/store";
export default {
  name: "adminmeet",
  data() {
    return {
      data: {},
    };
  },
  components: {
    ListScroll,
  },
  created() {
    this.data.phone = this.$tools.getPhone();
    this.loadData();
  },
  methods: {
    loadData() {
      this.$axios.get(this.$urls.admin.detail).then((res) => {
        this.data = { ...this.data, ...res.data };
        this.$tools.setLocalCookie("meetingCount", this.data);
      });
    },
    getAdminPhone() {
      let loginData = this.$tools.getLocalCookie("adminLoginData") || {};
      return loginData.login_name || "";
    },
    goFreezeMeeting(){
      this.$router.push({
        path: "/freezeMeetintList",
      });
    },
    openDetail(item) {
      this.$tools.setLocalCookie("doctorInfo", item);
      this.$router.push({
        path: "/doctermeet",
        query: {
          doctorId: item.id,
          allDoctorid: item.all_doctor_id,
        },
      });
    },
    //开始分配会议
    doAllocationMeet() {
      this.$tools.setLocalCookie("status", 1);
      this.$tools.setLocalCookie("doctorInfo", {});
      this.$router.push({
        path: "/meetinfo",
        query: {
          //   doctorId: store.state.common.adminInfo.user_id,
          // status: 1, //开始分配会议
        },
      });
    },
    doLoginOut() {
      this.$tools.setLocalCookie("adminLoginData", {});
      this.$router.push({
        path: "/login",
      });
    },
    openCoursewareDetail() {
      this.$router.push({
        path: "/preveiwcourseware",
      });
    },
    openBook() {
      this.$router.push({
        path: "/notebook",
        query: {
          type: 2,
        },
      });
    },
  },
};
</script>
<style >
.adminmeet {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 2vw 4vw;
  background: #f6f6f6;
}
.adminmeet_main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.adminmeet_title_block {
  flex: 10vw 0 0;
  display: flex;
  justify-content: center;
  margin-top: 4vw;
  position: relative;
}
.adminmeet_title {
  width: 50vw;
  height: 12vw;
  display: flex;
  font-size: 4.4vw;
  background: #008f3d;
  color: #fff;
  border-radius: 2vw;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  position: absolute;
  top: 4vw;
}
.adminmeet_title_bold {
  font-weight: bolder;
  font-size: 5vw;
  margin: 2vw;
}
.adminmeet_banner {
  border-radius: 4vw;
  flex: 40vw 0 0;
  background: #ffffff;
  padding: 14vw 8vw 4vw;
}
.adminmeet_banner_info{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.freezed_meeting{
  margin-top: 4vw;
  color: #008f3d;
  font-size: 3.6vw;
}
.adminmeet_banner_block {
  /* flex: 1 0 0; */
}
.adminmeet_banner_content {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  color: #b3b3b3;
  text-align: center;
}
.adminmeet_banner_line {
  height: 100%;
  flex: 4vw 0 0;
  display: flex;
  align-items: center;
}
.adminmeet_banner_line span {
  display: inline-block;
  height: 50%;
  width: 1px;
  background: #000;
}
.adminmeet_title_second {
  flex: 14vw 0 0;
  display: flex;
  align-items: center;
  font-size: 4vw;
  font-weight: 500;
  margin: 4vw 0;
  border-bottom: 1px solid #cfcfcf;
}
.adminmeet_list {
  flex: 1 0 0;
  position: relative;
}
.adminmeet_allocation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5vw;
  margin-top: 2vw;
  /* position: absolute;
  bottom: 10vw; */
}
.adminmeet_allocation_btn {
  border-radius: 2vw;
  border: 0.5vw solid #008f3d;
  font-weight: bold;
  color: #000;
  padding: 2vw 10vw;
}
.adminmeet_block {
  display: flex;
  height: 16vw;
  border-bottom: 1px solid #cfcfcf;
  /* border-radius: 2vw; */
  margin-bottom: 4vw;
  font-size: 3.4vw;
}
.adminmeet_block_img {
  height: 100%;
  flex: 12vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminmeet_block_img img {
  height: 8vw;
  width: 8vw;
  border-radius: 50%;
}
.adminmeet_block_content {
  flex: 1 0 0;
  display: flex;
  align-items: center;
}
.adminmeet_room {
  display: flex;
}
.adminmeet_room:nth-child(1) {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.adminmeet_room:nth-child(2) {
  flex: 24vw 0 0;
}
.adminmeet_room_key {
  font-weight: 400;
}
.adminmeet_room_next {
  display: flex;
  padding: 1vw 0;
}
.adminmeet_footer {
  flex: 8vw 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #b3b3b3;
  margin-bottom: 4vw;
}
.adminmeet_func_btn {
  padding-top: 2vw;
  display: flex;
  justify-content: flex-end;
  /* border:1px solid red; */
}
</style>